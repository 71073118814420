// Redux
import { AppDispatch, AppThunk } from './configureStore'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Images
import headerPlaceholder from '../assets/images/header-placeholder.png'

// Interfaces
import { SuccessUpdateVenueInfo, VenueInfo, BannerInterface } from './interfaces'

// Axios instances
import { venueManagerApi } from '../api/axios.config'

// Errors
import { ErrorsList } from '../common/errors'
import * as Sentry from '@sentry/react'

const venueInfoSlice = createSlice({
	name: 'venueInfo',
	initialState: {
		_id: null,
		venueName: {
			pt: 'Tagme',
			en: 'Tagme',
			fr: 'Tagme',
			es: 'Tagme',
		},
		city: '',
		neighborhood: '',
		logoVertical: null,
		logoHorizontal: null,
		smallBackgroundImage: headerPlaceholder,
		largeBackgroundImage: headerPlaceholder,
		color: {
			main: null,
			background: null,
			accentBackground: null,
			border: null,
		},
		maxPartySize: 1,
		typeImageSpotlight: null,
		slug: null,
		hasMenu: false,
		sections: [],
	} as VenueInfo,
	reducers: {
		venueInfoUpdated(previousState: VenueInfo, action: PayloadAction<Partial<VenueInfo>>) {
			const { payload } = action

			return { ...previousState, ...payload }
		},
	},
})

export const { venueInfoUpdated } = venueInfoSlice.actions
export default venueInfoSlice.reducer

// Thunk for fetching data in the API and updating the global state venueInfo
export function fetchVenueInfoData(venueId: string): AppThunk | SuccessUpdateVenueInfo {
	return async (dispatch: AppDispatch) => {
		let objReturn: SuccessUpdateVenueInfo = {
			success: true,
			errorsMessages: [],
		}

		let partialVenueInfo

		try {
			// Fetch the data in the venueManagerApi (venueName and address - Get Venue Info)
			const venueInfoResponse = await venueManagerApi.get(`/venues/${venueId}`)

			let venueInfoData = venueInfoResponse?.data

			if (venueInfoData) {
				let venueInfoObj = {
					_id: venueId,
					slug: venueInfoData.slug,
					venueName: venueInfoData.name,
					city: venueInfoData.address.city,
					neighborhood: venueInfoData.address.neighborhood,
					hasMenu: venueInfoData.hasMenu,
				}

				partialVenueInfo = venueInfoObj

				dispatch(venueInfoUpdated(venueInfoObj))

				sessionStorage.setItem('venueInfo', JSON.stringify(partialVenueInfo))
			} else {
				// For the cases in which the requisition doesn't throw an exception but returns null
				objReturn.success = false
				objReturn.errorsMessages.push(ErrorsList.VENUE_INFO_NOT_FOUND)
			}
		} catch (error) {
			// For the case in which the requisition throws an exception
			Sentry.setTag('venueId', venueId)
			Sentry.captureException(new Error(ErrorsList.FAILED_TO_GET_VENUE_INFO_FROM_CUSTOMER_API))
		}

		try {
			// Fetch the data in the venueManagerApi (max partySizesWidget - Get Waitlist Settings)
			const waitlistSettingsResponse = await venueManagerApi.get(`/waitlists/${venueId}/party-sizes`)
			const waitlistSettingsData = waitlistSettingsResponse?.data[0]

			if (waitlistSettingsData) {
				let waitlistSettingsObj = { maxPartySize: waitlistSettingsData?.partySizesWidget?.max, sections: [] }

				// OT-930 - Adding function to include section for "confeitaria colombo forte de copacabana"
				if (venueId === '606b1b916544db26c4c6df7f') {
					waitlistSettingsObj.sections = waitlistSettingsData?.sections
				}

				partialVenueInfo = { ...partialVenueInfo, ...waitlistSettingsObj }

				dispatch(venueInfoUpdated(waitlistSettingsObj))

				sessionStorage.setItem('venueInfo', JSON.stringify(partialVenueInfo))
			} else {
				// For the cases in which the requisition doesn't throw an exception but returns null
				objReturn.success = false
				objReturn.errorsMessages.push(ErrorsList.WAITLIST_SETTINGS_NOT_FOUND)
			}
		} catch (error) {
			// For the case in which the requisition throws an exception
			Sentry.setTag('venueId', venueId)
			Sentry.captureException(
				new Error(ErrorsList.FAILED_TO_GET_WAITLIST_SETTINGS_UNAUTHENTICATED_FROM_LEGACY_API)
			)
		}

		try {
			// Fetch the data in the venueManagerApi (logo and backgroundImage - Get Theme)
			const themeResponse = await venueManagerApi.get(`/themes/tagme-api/${venueId}`)
			const themeData = themeResponse?.data

			// If the logo vertical is not available, it will be set as null
			const logoVertical = themeData?.logo?.vertical
				? `${process.env.REACT_APP_BLOB_BASE_URL}/${themeData.logo.vertical}`
				: null

			// If the logo horizontal or white is not available, it will be set as null
			const logoHorizontal = themeData?.logo?.horizontal
				? `${process.env.REACT_APP_BLOB_BASE_URL}/${themeData.logo.horizontal}`
				: themeData?.logo?.horizontalWhite
					? `${process.env.REACT_APP_BLOB_BASE_URL}/${themeData.logo.horizontalWhite}`
					: null

			// The following attribute defines which type of backgroud-image should be used in the venueHeaders
			const typeImageSpotlight = themeData?.typeImageSpotlight

			let smallBackgroundImage = ''
			let largeBackgroundImage = ''

			if (typeImageSpotlight === 'Inspirational') {
				// If the inspirational image is not available it will be set to a default imageUrl
				smallBackgroundImage = isValidUrl(themeData?.inspirationalImage?.small)
					? `${process.env.REACT_APP_BLOB_BASE_URL}/${themeData.inspirationalImage.small}`
					: headerPlaceholder

				// If the inspirational image is not available it will be set to a default imageUrl
				largeBackgroundImage = isValidUrl(themeData?.inspirationalImage?.large)
					? `${process.env.REACT_APP_BLOB_BASE_URL}/${themeData.inspirationalImage.large}`
					: headerPlaceholder
			} else if (typeImageSpotlight === 'Banners') {
				const bannerList: BannerInterface[] = themeData?.banners.list
				let randomBanner: BannerInterface | undefined

				if (bannerList) {
					// It gets an random banner
					const activeBanners = bannerList.filter(banner => banner.isActive)
					const randomIndex = Math.floor(activeBanners.length * Math.random())
					randomBanner = activeBanners[randomIndex]
				}

				// If the banner image is not available it will be set to a default imageUrl
				smallBackgroundImage = isValidUrl(randomBanner?.imageUrl)
					? `${process.env.REACT_APP_BLOB_BASE_URL}/${randomBanner?.imageUrl}`
					: headerPlaceholder

				// In the case of the banner the large and small background image will be the same
				largeBackgroundImage = smallBackgroundImage
			} else {
				smallBackgroundImage = headerPlaceholder
				largeBackgroundImage = headerPlaceholder
			}

			if (themeData) {
				let themeObj = {
					color: themeData.color,
					logoVertical,
					logoHorizontal,
					typeImageSpotlight,
					smallBackgroundImage,
					largeBackgroundImage,
				}

				partialVenueInfo = { ...partialVenueInfo, ...themeObj }

				dispatch(venueInfoUpdated(themeObj))

				sessionStorage.setItem('venueInfo', JSON.stringify(partialVenueInfo))
			} else {
				// For the cases in which the requisition doesn't throw an exception but returns null
				objReturn.success = false
				objReturn.errorsMessages.push(ErrorsList.THEME_NOT_FOUND)
			}
		} catch (error) {
			// For the case in which the requisition throws an exception
			Sentry.setTag('venueId', venueId)
			Sentry.captureException(new Error(ErrorsList.FAILED_TO_GET_THEME_FROM_CUSTOMER_API))
		}
		return objReturn
	}
}

function isValidUrl(url: string | null | undefined) {
	return !!url && url !== 'undefined'
}
