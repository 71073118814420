import axios from 'axios'

// * VENUE MANAGER API
// Config this axios intance to reiceive the base URL bellow
const venueManagerApi = axios.create({
	baseURL: process.env.REACT_APP_VENUE_MANAGER_BASE_URL,
})
// * VENUE MANAGER API

export { venueManagerApi }
