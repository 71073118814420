import Pusher from 'pusher-js/with-encryption'

export class PusherService {
	pusher: any
	channel: any

	constructor() {
		const pusherKey = process.env.REACT_APP_PUSHER_KEY

		if (!pusherKey) throw new Error('MISSING_PUSHER_KEY')

		this.pusher = new Pusher(pusherKey)
	}

	subscribe(venueId: string) {
		this.channel = this.pusher.subscribe(venueId)
	}
}
