//React
import { useEffect, useState } from 'react'

// Redux
import { useSelector } from 'react-redux'
import { RootState } from '../../store/reducer'

//i18next
import i18next from 'i18next'

//Styles
import { TranslatorStyles } from './styles'

//Images
import * as flag from '../../assets/images/languages'
import checkSelected from '../../assets/images/check-selected.svg'
import checkNotSelected from '../../assets/images/check-not-selected.svg'

//Interfaces
import { Languages, LanguageSelectorProps } from './interfaces'
import { ModalTypes } from '../../Components/TagmeModal/interface'

//Components
import TagmeModal from '../TagmeModal'
import { COLORS } from '../../common/constants'

function LanguageSelector(props: LanguageSelectorProps) {
	const { minForm } = props

	// CONFIGURATION IN SRC/INDEX.TSX
	const languagesList: Languages[] = [
		{
			code: 'pt',
			name: 'PT-BR',
			fullName: 'Português do Brasil',
			country_code: 'br',
			flag: flag.flagPtBr,
		},
		{
			code: 'en',
			name: 'EN-US',
			fullName: 'American English',
			country_code: 'us',
			flag: flag.flagEnUs,
		},
	]

	// Access the globalState venueInfo
	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)

	const storageLanguageCode = localStorage.getItem('i18nextLng') || 'pt'

	const storageLanguage = languagesList.find(l => l.code === storageLanguageCode) || languagesList[0]

	const [currentLanguage, setCurrentLanguage] = useState(storageLanguage)

	const [showModalLanguage, setShowModalLanguage] = useState(false)

	useEffect(() => {
		localStorage.setItem('i18nextLng', currentLanguage.code)
	}, [currentLanguage])

	const handleGenerateLanguageList = () => {
		const options = []

		for (let i = 0; i < languagesList.length; i++) {
			options.push(
				<div
					className={`option_language${i === languagesList.length - 1 ? ' last_option' : ''}`}
					key={languagesList[i].code}
					onClick={() => handleSelectLanguage(languagesList[i])}
				>
					<div className="display_alignment" data-cy={`${languagesList[i].code}_language_div`}>
						<img src={languagesList[i].flag} alt={languagesList[i].name} />
						<p className="option_language_name">{languagesList[i].fullName}</p>
					</div>

					<div id="div_toogle_select">
						{currentLanguage.name === languagesList[i].name ? (
							<img className="toogle_select" src={checkSelected} alt="selected" />
						) : (
							<img className="toogle_select" src={checkNotSelected} alt="notSelected" />
						)}
					</div>
				</div>
			)
		}

		return options
	}

	const handleSelectLanguage = (language: Languages) => {
		i18next.changeLanguage(language.code)
		setCurrentLanguage(language)
		handleCloseModal()
	}

	const handleTagmeModal = () => {
		return (
			<TagmeModal
				show={showModalLanguage}
				type={ModalTypes.error}
				onClose={handleCloseModal}
				hideCloseButton={true}
			>
				<div className="language_select" data-cy="language_modal">
					{handleGenerateLanguageList()}
				</div>
			</TagmeModal>
		)
	}

	const handleShowModal = () => {
		setShowModalLanguage(!showModalLanguage)
	}

	const handleCloseModal = () => {
		setShowModalLanguage(false)
	}

	return (
		<TranslatorStyles
			borderColor={venueInfo.color.border || COLORS.border}
			colorMain={venueInfo.color.main || COLORS.main}
			minForm={minForm || false}
		>
			<ul className="language_list" onClick={handleShowModal} data-cy="language_selector">
				<div id="div_selected_language">
					<img src={currentLanguage.flag} alt={currentLanguage.name} />
					<p className="selected_language">{minForm ? ' ' : currentLanguage.name}</p>
					<i
						className={`tagmeicon tagmeicon-seta select-arrow ${
							showModalLanguage ? 'rotate-select-arrow' : ''
						}`}
					></i>
				</div>
			</ul>

			{handleTagmeModal()}
		</TranslatorStyles>
	)
}

export default LanguageSelector
