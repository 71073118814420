import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

// Redux
import { useSelector } from 'react-redux'
import { RootState } from '../../store/reducer'

// Axios instances
import { venueManagerApi } from '../../api/axios.config'

// Languages
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

// Styles
import { FooterStyles } from './styles'

// Components
import TagmeInput from '../TagmeInput'
import TagmeModal from '../TagmeModal'
import TagmeButton from '../TagmeButton'
import TagmeSelect from '../TagmeSelect'
import DisclamerServiceTerms from '../DisclamerServiceTerms'

// Interfaces
import { LanguageTypes } from '../../common/interfaces'
import { ModalProps, ModalTypes } from '../TagmeModal/interface'

// Errors
import * as Sentry from '@sentry/react'
import { ErrorsList } from '../../common/errors'
import { COLORS } from '../../common/constants'

//TextsDinamics
import { venuesListSpecial } from '../../common/textDinamic'

function RemoteWaitlistForm({ fetchStatusData }: any) {
	const history = useHistory()

	const [modalInfo, setModalInfo] = useState<ModalProps>({
		show: false,
		type: ModalTypes.error,
	})

	// Extract the current language from the i18next
	const language = i18n.language as LanguageTypes

	// Handle the text translation
	const { t } = useTranslation()

	// Global state
	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)

	// Form States
	const [partySize, setPartySize] = useState('')
	const [name, setName] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [email, setEmail] = useState('')
	const [observation, setObservation] = useState('')
	const [section, setSection] = useState('')
	const [selectedSectionValue, setSelectedSectionValue] = useState('');
	const [showSelectedSectionPhrase, setShowSelectedSectionPhrase] = useState(false);

	// Name validation state
	const [nameValidation, setNameValidation] = useState({
		nameHasError: false,
		nameIsValid: false,
	})

	// Phone validation state
	const [phoneNumberValidation, setPhoneNumberValidation] = useState({
		phoneHasError: false,
		phoneIsValid: false,
	})

	// Email validation state
	const [emailValidation, setEmailValidation] = useState({
		emailHasError: false,
		emailIsValid: false,
	})

	// Select validation state
	const [selectValidation, setSelectValidation] = useState({
		selectHasError: false,
		selectIsValid: false,
	})

	// Function that given the maxPartySize fetched from the API, generates the partySizeOptions html automatically
	const generatePartySizeOptions = () => {
		const partySizeOptions = []

		for (let i = 1; i <= venueInfo.maxPartySize; i++) {
			const obj = {
				label: `${i} ${t('seats')}`,
				value: i,
			}

			if (i === 1) {
				obj.label = `${i} ${t('seat')}`
			}

			partySizeOptions.push(obj)
		}

		return partySizeOptions
	}

	const generateSectionsOptions = () => {
		const sectionsOptions = []

		for (const section in venueInfo.sections) {
			const obj = {
				label: venueInfo.sections[+section],
				value: venueInfo.sections[+section],
			}

			sectionsOptions.push(obj)
		}
		return sectionsOptions
	}

	const [sectionValidation, setSectionValidation] = useState({
		sectionHasError: false,
		sectionIsValid: false,
	});

	// Validates section automatically
	useEffect(() => {
		if (section) {
			setSectionValidation({ sectionHasError: false, sectionIsValid: true });
		}
	}, [section]);

	// Function to validate section
	const validateSection = (sectionValue: string) => {
		return !!sectionValue;
	};

	// Updates the validation state of the section field
	const updateSectionValidation = (sectionValue: string) => {
		const isValid = validateSection(sectionValue);
		setSectionValidation({ sectionHasError: !isValid, sectionIsValid: isValid });
	};

	// Updates the state of the section field
	const handleSectionChange = (value: string) => {
		setSection(value);
		updateSectionValidation(value);
		setSelectedSectionValue(value);
		setShowSelectedSectionPhrase(true)
	};

	const closeModal = useCallback(() => {
		setModalInfo(previousModalInfoState => ({ ...previousModalInfoState, show: false }))
	}, [])

	// By making 'buttonDisabled' not a const, I can change it to false after clicking it, preventing multiples requests.
	let [buttonDisabled, toggleButtonStatus] = useState(true)

	// Validates partySize automatically
	useEffect(() => {
		if (partySize) {
			setSelectValidation({ selectHasError: false, selectIsValid: true })
		}
	}, [partySize])

	// Validates name automatically
	useEffect(() => {
		const checkName = name.match(/^([A-zÁ-ú]){2,}\s([A-zÁ-ú.]\s?){2,}/)

		if (name) {
			if (!checkName) {
				setNameValidation({
					nameHasError: true,
					nameIsValid: false,
				})
			} else {
				setNameValidation({
					nameHasError: false,
					nameIsValid: true,
				})
			}
		}
	}, [name])

	// Validates phoneNumber automatically
	useEffect(() => {
		const checkPhoneNumber = phoneNumber.match(/^(\+\d{1,4}\s?)?(\(\d{1,4}\)\s?)?\d{9,}$/)

		if (phoneNumber) {
			if (!checkPhoneNumber) {
				setPhoneNumberValidation({
					phoneHasError: true,
					phoneIsValid: false,
				})
			} else {
				setPhoneNumberValidation({
					phoneHasError: false,
					phoneIsValid: true,
				})
			}
		}
	}, [phoneNumber])

	// Validates email automatically
	useEffect(() => {
		const checkEmail = email.match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)

		if (email) {
			if (checkEmail && checkEmail[0] === email) {
				setEmailValidation({
					emailHasError: false,
					emailIsValid: true,
				})
			} else {
				setEmailValidation({
					emailHasError: true,
					emailIsValid: false,
				})
			}
		} else {
			setEmailValidation({
				emailHasError: false,
				emailIsValid: false,
			})
		}
	}, [email])

	// If all the fields are properlly filled, it will toggle the state of the submit button to disabled=false, otherwise it will toggle to disabled=true
	useEffect(() => {

		// If the venueId "606b1b916544db26c4c6df7f" is used, the section field is mandatory
		if (venueInfo._id === '606b1b916544db26c4c6df7f') {
			if (
				!emailValidation.emailHasError &&
				nameValidation.nameIsValid &&
				phoneNumberValidation.phoneIsValid &&
				selectValidation.selectIsValid &&
				sectionValidation.sectionIsValid
			) {
				toggleButtonStatus(false)
			} else {
				toggleButtonStatus(true)
			}
		} else {
			if (
				!emailValidation.emailHasError &&
				nameValidation.nameIsValid &&
				phoneNumberValidation.phoneIsValid &&
				selectValidation.selectIsValid
			) {
				toggleButtonStatus(false)
			} else {
				toggleButtonStatus(true)
			}
		}
	}, [emailValidation, nameValidation, phoneNumberValidation, selectValidation, sectionValidation, venueInfo._id])

	useEffect(() => {
		if (venueInfo._id === '606b1b916544db26c4c6df7f') {
			if (showSelectedSectionPhrase) {
				setObservation(`Localização: ${selectedSectionValue}`);
			} else {
				setObservation('');
			}
		}
	}, [selectedSectionValue, venueInfo._id, showSelectedSectionPhrase]);

	// Form Submit
	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		const isActive = await fetchStatusData()

		if (!isActive) return

		// If the button status is disabled=true it means that some of the fields is not properly filled,
		// so it won't post the form and will show which field is incorrect.
		if (buttonDisabled) {
			// It will show that the field name is required and has error
			if (!nameValidation.nameHasError && !nameValidation.nameIsValid) {
				setNameValidation({ ...nameValidation, nameHasError: true })
			}

			// It will show that the field phoneNumber is required and has error
			if (!phoneNumberValidation.phoneHasError && !phoneNumberValidation.phoneIsValid) {
				setPhoneNumberValidation({
					...phoneNumberValidation,
					phoneHasError: true,
				})
			}

			// It will show that the field select is required and has error
			if (!selectValidation.selectHasError && !selectValidation.selectIsValid) {
				setSelectValidation({ ...selectValidation, selectHasError: true })
			}
		}
		// If the button status is disabled=false, it means that everything is good and that we can proceed with the post
		else {
			buttonDisabled = true

			try {
				// Prepare the data to be posted
				const data = {
					customer: {
						name: name,
						phone: phoneNumber,
						email: email,
					},
					venue: venueInfo._id,
					origin: {
						label: 'Widget',
					},
					partySize: partySize,
					benefits: '',
					logs: {
						actionType: 'Add',
					},
					data: {
						origin: 'Waitlist widget',
					},
					sendSms: true,
					note: observation,
				}

				// Effectivelly post the data and insert the user in the waitlist
				const response = await venueManagerApi.post(`/waitlists/${venueInfo._id}/create`, data)

				// Extracts the waitlistId from the response data
				const waitlistId = response.data._id

				waitlistId &&
					setModalInfo({
						show: true,
						closable: false,
						type: ModalTypes.success,
						title: t("You've joined the waitlist"),
						message: [
							"We've sent a SMS with a link for you to follow your position in the waitlist.",
							'Look for the receptionist when you arrive.',
						],
						buttons: [
							{
								label: 'Follow',
								action: () => {
									history.push(`/waitlist/${waitlistId}`)
								},
							},
						],
					})
			} catch (error) {
				setModalInfo({
					show: true,
					onClose: closeModal,
					type: ModalTypes.error,
					message: 'Failed to create waitlist.',
					buttons: [
						{
							label: 'OK',
							action: closeModal,
						},
					],
				})

				Sentry.setTag('venueId', venueInfo._id)
				Sentry.captureException(new Error(ErrorsList.FAILED_TO_CREATE_NEW_WAITLIST))
			}
		}
	}

	return (
		<>
			<form onSubmit={handleSubmit}>
				<TagmeSelect
					name="partySize"
					icon="tagmeicon-fila-2"
					label={
						venuesListSpecial.includes(venueInfo._id as string)
							? t('Choose the number of people:')
							: t('Table for how many people?')
					}
					placeholder={t('Select')}
					options={generatePartySizeOptions()}
					border={true}
					featuredEnabled={true}
					valid={selectValidation.selectIsValid}
					hasError={selectValidation.selectHasError}
					state={partySize}
					setState={setPartySize}
				/>

				{partySize ? (
					<>
						<div className="personal_data">{t('Personal Data')}</div>

						<TagmeInput
							state={phoneNumber}
							setState={setPhoneNumber}
							border={true}
							icon="tagmeicon-telefone"
							label={t('Phone Number')}
							placeholder="Ex.: (21) 99315-7373"
							name="phoneNumber"
							type="text"
							mask="(99) 99999-9999"
							hint={t('Required')}
							featuredEnabled={true}
							hasError={phoneNumberValidation.phoneHasError}
							valid={phoneNumberValidation.phoneIsValid}
							errorMsg={t('Invalid phone number')}
						/>

						<TagmeInput
							state={name}
							setState={setName}
							border={true}
							icon="tagmeicon-nome"
							label={t('Name and Surname')}
							placeholder="Ex.: João Silva"
							name="name"
							type="text"
							hint={t('Required')}
							featuredEnabled={true}
							hasError={nameValidation.nameHasError}
							valid={nameValidation.nameIsValid}
							errorMsg={t('Insert Surname')}
						/>

						{venueInfo._id === '606b1b916544db26c4c6df7f' && (
							<TagmeSelect
								name="section"
								icon="tagmeicon-localizacao"
								label={t('Location (Required)')}
								placeholder={t('Select')}
								options={generateSectionsOptions()}
								border={true}
								featuredEnabled={true}
								valid={sectionValidation.sectionIsValid}
								hasError={sectionValidation.sectionHasError}
								state={section}
								setState={handleSectionChange}
							/>
						)}

						<TagmeInput
							state={email}
							setState={setEmail}
							border={true}
							icon="tagmeicon-email"
							label={t('Email')}
							placeholder="Ex.: joao@gmail.com"
							name="email"
							type="text"
							featuredEnabled={true}
							hasError={emailValidation.emailHasError}
							valid={emailValidation.emailIsValid && !emailValidation.emailHasError}
							errorMsg={t('Invalid email')}
						/>

						<TagmeInput
							state={observation}
							setState={setObservation}
							border={true}
							icon="tagmeicon-editar"
							label={t('Remarks and requests')}
							placeholder={t('There is no guarantee that they will be served.')}
							name="observation"
							type="text"
							featuredEnabled={false}
							maxLength={255}
						/>
					</>
				) : (
					<></>
				)}
				<DisclamerServiceTerms colorMain={venueInfo.color.main} venueName={venueInfo.venueName[language]} />

				<FooterStyles
					colorBorder={venueInfo.color.border || COLORS.border}
					colorBackground={venueInfo.color.background || COLORS.background}
				>
					<TagmeButton
						type="button"
						label={t('Get in waitlist')}
						colorMain={venueInfo.color.main}
						disabled={buttonDisabled}
						handleClick={handleSubmit}
					/>
				</FooterStyles>
			</form>

			<TagmeModal {...modalInfo} />
		</>
	)
}

export default RemoteWaitlistForm
