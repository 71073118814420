// Style Component
import { Container } from './styles'

// Languages
import { useTranslation } from 'react-i18next'

// Redux
import { useSelector } from 'react-redux'
import { RootState } from '../../store/reducer'
import { COLORS } from '../../common/constants'

function ServiceSelector() {
	const { t } = useTranslation()

	// Access the globalState venueInfo
	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)

	const handleClick = () => {
		window.location.href = `${process.env.REACT_APP_RESERVATION_WIDGET_URL}/smartlink/${venueInfo._id}`
	}

	return (
		<Container
			colorMain={venueInfo.color.main || COLORS.main}
			colorBorder={venueInfo.color.border || COLORS.background}
		>
			<div className='intro'>
				<div className='icon'>
					<i className='tagmeicon tagmeicon-fila'></i>
				</div>

				<div className='desc'>
					<h2>{t('Join the waitlist')}</h2>
					<p>{t('Secure your place in waitlist')}</p>
				</div>

				<div className='action'>
					<button onClick={handleClick} data-cy='change_button'>
						{t('Change')}
					</button>
				</div>
			</div>
		</Container>
	)
}

export default ServiceSelector
