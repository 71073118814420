// React
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router'

// Redux
import { RootState } from '../../store/reducer'
import { useDispatch, useSelector } from 'react-redux'

// Actions
import { fetchVenueInfoData, venueInfoUpdated } from '../../store/venueInfo'

// Axios instances
import { venueManagerApi } from '../../api/axios.config'

// Styles
import { ContainerStyles } from './styles'
import { GlobalStyle } from '../../styles/global'

// Components
import TagmeModal from '../../Components/TagmeModal'
import WaitlistHeader from '../../Components/WaitlistHeader'
import ServiceSelector from '../../Components/ServiceSelector'
import RemoteWaitlistForm from '../../Components/RemoteWaitlistForm'

// Errors
import { ErrorsList } from '../../common/errors'
import * as Sentry from '@sentry/react'

// Interfaces
import { RemoteWailistProps } from './interfaces'
import { ModalProps, ModalTypes } from '../../Components/TagmeModal/interface'
import { SuccessUpdateVenueInfo } from '../../store/interfaces'

// Utils
import { handleSessionStorage } from '../../utils/sessionStorageManagement'
import { COLORS } from '../../common/constants'

import { venuesListSpecial } from '../../common/textDinamic'

import { useTranslation } from 'react-i18next'

function RemoteWaitList() {
	const history = useHistory()

	const [modalInfo, setModalInfo] = useState<ModalProps>({
		show: false,
		type: ModalTypes.error,
	})

	// Extract the route param venueId from the url
	const { venueId } = useParams<RemoteWailistProps>()

	const dispatch = useDispatch()

	// Access the globalState venueInfo
	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)

	// Function for redirecting the client to the smartLink page
	const handleToSmartlink = useCallback(() => {
		window.location.href = `${process.env.REACT_APP_RESERVATION_WIDGET_URL}/smartlink/${venueId}`
	}, [venueId])

	const closeModal = useCallback(() => {
		setModalInfo(previousModalInfoState => ({ ...previousModalInfoState, show: false }))
	}, [])

	//Handle the text translation
	const { t } = useTranslation()

	const fetchStatusData = useCallback(async () => {
		try {
			// Fetch the information about the systems availability
			const waitlistStatusResponse = await venueManagerApi.get(`/waitlists/${venueId}/waitlist-statuses`)

			const { locked, active } = waitlistStatusResponse.data.waitlist

			const closed = locked || !active

			if (closed) {
				setModalInfo({
					show: true,
					closable: false,
					type: ModalTypes.noIcon,
					message: locked
						? `${t('Remote waitlist closed.')}`
						: `${t('No waitlist available at the moment.')}`,
					buttons: [
						{
							label: 'OK',
							action: handleToSmartlink,
						},
					],
				})

				return false
			}

			return true
		} catch (error) {
			setModalInfo({
				show: true,
				onClose: closeModal,
				type: ModalTypes.error,
				message: 'Venue not found.',
				buttons: [
					{
						label: 'OK',
						action: () => history.push(`/404-not-found`),
					},
				],
			})

			Sentry.setTag('venueId', venueId)
			Sentry.captureException(new Error(ErrorsList.FAILED_TO_GET_WAITLIST_STATUS))

			return false
		}
	}, [closeModal, handleToSmartlink, history, t, venueId])

	// It checks the venue availability
	useEffect(() => {
		fetchStatusData()
	}, [fetchStatusData])

	// Update the venueInfo
	useEffect(() => {
		async function fetchVenueData() {
			try {
				// Fetch the information available in the sessionStorage
				const { sessionStorageFilled, sessionStorageId, parsedSessionStorage } =
					handleSessionStorage('venueInfo')

				// The conditionals bellow only will be checked if the venueInfo (global state) is not filled
				// If the sessionStorage is properly filled, the venueInfo will be set based on this information,
				// avoiding unncessary resquests
				if (sessionStorageFilled && sessionStorageId === venueId) {
					return dispatch(venueInfoUpdated(parsedSessionStorage))
				}

				// Thunk Action for fetching data in the API and updating the global state venueInfo
				const successUpdateVenueInfo = (await dispatch(fetchVenueInfoData(venueId))) as SuccessUpdateVenueInfo

				if (successUpdateVenueInfo.errorsMessages.length > 0) {
					let hasOnlyThemeError = false

					for (const errorMessage of successUpdateVenueInfo.errorsMessages) {
						hasOnlyThemeError =
							successUpdateVenueInfo.errorsMessages.length === 1 && errorMessage.includes('THEME')
						Sentry.setTag('venueId', venueId)
						Sentry.captureMessage(errorMessage, Sentry.Severity.Warning)
					}

					if (!hasOnlyThemeError) {
						setModalInfo({
							show: true,
							closable: false,
							type: ModalTypes.noIcon,
							message: 'Waitlist is currently inaccessible.',
							buttons: [
								{
									label: 'OK',
									action: handleToSmartlink,
								},
							],
						})
					}
				}
			} catch (error) {
				Sentry.setTag('venueId', venueId)
				Sentry.captureException(new Error(ErrorsList.UNKNOWN_ERROR))
			}
		}

		// If the venueInfo is filled, we don't need to proceed, so the fetchVenueData function bellow won't be called
		if (!venueInfo._id) {
			fetchVenueData()
		}
	}, [closeModal, dispatch, handleToSmartlink, history, venueId, venueInfo._id])

	const handlePriorityDisclaimer = () => {
		const priorityText = venuesListSpecial.includes(venueInfo._id as string)
			? `${t('In case of priority, look for one of our attendants when you arrive at the establishment.')}`
			: `${t('In case of priority, look for one of our attendants when you arrive at the restaurant.')}`
		return (
			<div id="div_priority_disclaimer">
				<p>{priorityText}</p>
			</div>
		)
	}
	return (
		<ContainerStyles
			colorBackground={venueInfo.color.background || COLORS.background}
			colorBorder={venueInfo.color.border || COLORS.border}
		>
			<WaitlistHeader />

			<ServiceSelector />

			{handlePriorityDisclaimer()}

			<RemoteWaitlistForm fetchStatusData={fetchStatusData} />

			<TagmeModal {...modalInfo} />

			<GlobalStyle
				colorBackground={venueInfo.color.background || COLORS.background}
				modalOpened={modalInfo.show}
			/>
		</ContainerStyles>
	)
}

export default RemoteWaitList
