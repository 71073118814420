export interface WaitListResponseParams {
	waitlistId: string
}

export enum OperationModeType {
	ADVANCED = 'advanced',
	BASIC = 'basic',
}

export interface WaitListInfo {
	status: string | null
	globalPos: number
	displayHelpers: {
		partyLabel: string
		priorityText: string
	}
	pos: number
	priority: boolean | null
	_id: string
	arrivedAt: string
	customer: {
		_id: string
		name: string
	}
	customerTab: string
	estimatedTime: string
	origin: {
		_id: string
		label: string
	}
	partySize: number
	venue: string
	hideCustomerPosition: boolean
	operationMode: OperationModeType
}
