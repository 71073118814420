import styled from 'styled-components'
//TODO COLOCAR A COR DE SELECIONADO

export const TagmeSelectStyles = styled('div')<{
	colorMain: string
	colorBackground: string
	colorAccentBackground: string
	colorBorder: string
}>`
	.valid {
		background: ${p => p.colorAccentBackground};
	}

	.no_border {
		border: none;
	}

	.has_error {
		color: #de1d48;
	}

	.has_error .text_container {
		.label {
			color: #de1d48 !important;
		}
	}

	.container {
		display: flex;
		padding: 11px 0;
		position: relative;
		margin-bottom: 5px;
		border-top: 1px solid ${p => p.colorBorder};
		border-bottom: 1px solid ${p => p.colorBorder};

		.form-group {
			width: 100%;
			margin-bottom: 0;

			.input-group {
				display: flex;
				position: unset;
				max-height: 45px;
				flex-wrap: nowrap;
				justify-content: space-between;

				.input-items {
					width: 100%;
					display: flex;

					.text_container {
						width: 100%;
						font-size: 12px;

						.label {
							margin: 0;
							color: #4a4a4a;
							cursor: pointer;
							font-size: 14px;
							line-height: 16px;
							font-weight: normal;
						}

						.label_value_selected {
							border: 0;
							margin: 0;
							color: #545454;
							font-size: 14px;
						}

						input {
							padding: 0;
						}
					}
				}

				.icon_container {
					margin-left: 10px;
					margin-right: 10px;

					.icon {
						font-size: 28px;
					}

					.valid {
						left: 26px;
						top: 14px;
						width: 14px;
						height: 14px;
						display: flex;
						color: #f1f1f1;
						margin-right: 2px;
						border-radius: 50%;
						position: absolute;
						align-items: center;
						justify-content: center;
						background-color: #71c879;
					}
				}

				.clickable {
					text-decoration: underline;
					color: ${props => props.colorMain}; //var(--primary-color);
				}

				.select-arrow {
					font-size: 24px;
					font-weight: 900;
					margin: 6px 11px 0 0;
					transform: rotate(90deg);
				}

				.rotate-select-arrow {
					transform: rotate(270deg);
				}
			}

			input {
				width: 300px;
				border: none;
				height: 18px;
				max-width: 100%;
				box-shadow: none;
			}
		}

		.dropdown {
			top: 55px;
			z-index: 1;
			width: 100%;
			max-height: 140px;
			overflow-y: scroll;
			position: absolute;
			border-radius: 3px;
			background-color: ${props => props.colorBackground};
			box-shadow: 0px 1px 6px rgb(0 0 0 / 20%);

			.item {
				font-size: 12px;
				padding: 10px 24px;

				&:hover {
					cursor: pointer;
					background-color: #f8f9fa;
				}
			}
		}

		.invisible {
			display: none;
		}
	}

	.alert_message {
		display: flex;
		color: #de1d48;
		font-size: 14px;
		font-weight: 300;
		line-height: 18px;
		justify-content: center;
	}
`
