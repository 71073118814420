import styled from 'styled-components'

export const ContainerStyles = styled('div')<{
	colorBackground: string
	colorBorder: string
}>`
	padding: 0 10px;
	background-color: ${props => props.colorBackground};

	@media (min-width: 1024px) {
		margin-left: 20%;
		margin-right: 20%;
		//width: max-content;
	}

	.personal_data {
		width: 99%;
		height: 100%;
		font-size: 14px;
		margin-top: 5px;
		padding: 5px 0 5px 3px;
		background-color: ${props => props.colorBackground};
		border-top: 1px solid ${props => props.colorBorder};
		border-bottom: 1px solid ${props => props.colorBorder};
	}

	.partySizeReminder {
		color: #de1d48;
		font-size: 14px;
		font-weight: 300;
		line-height: 18px;
		margin: 10px auto;
		font-style: normal;
		text-align: center;
	}

	#div_priority_disclaimer {
		margin: 8px auto;
		border-radius: 3px;
		background: #fffde5;
		box-sizing: border-box;
		border: 1px solid #ffe5a4;

		p {
			color: #6b4a15;
			font-size: 12px;
			padding: 0 10px;
			line-height: 18px;
		}

		@media (min-width: 620px) {
			text-align: center;
		}
	}
`
