import { useEffect, useRef, useState } from 'react'

// Redux
import { useSelector } from 'react-redux'
import { RootState } from '../../store/reducer'

// Translation
import { useTranslation } from 'react-i18next'

// Interfaces
import { Options, TagmeSelectProps } from './interfaces'

// Styles
import { TagmeSelectStyles } from './styles'
import { COLORS } from '../../common/constants'

//TextsDinamics
import { venuesListSpecial } from '../../common/textDinamic'

function TagmeSelect(props: TagmeSelectProps) {
	const {
		name,
		icon,
		label,
		placeholder,
		options,
		border,
		featuredEnabled,
		valid,
		hasError,
		state,
		setState,
	} = props

	const ref: any = useRef()

	// Access the globalState venueInfo
	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)
	const { _id: venueId } = venueInfo

	// Handle the text translation
	const { t } = useTranslation()

	const [dropdownVisibility, toggleDropdownVisibility] = useState(false)
	const [labelOptionsSelected, setLabelOptionsSelected] = useState('')

	useEffect(() => {
		const checkIfClickedOutside = (e: Event) => {
			// If the menu is open and the clicked target is not within the menu, then close the menu
			if (dropdownVisibility && ref.current && !ref.current.contains(e.target)) {
				toggleDropdownVisibility(false)
			}
		}

		document.addEventListener('mousedown', checkIfClickedOutside)

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside)
		}
	}, [dropdownVisibility])

	const toggleDropdown = () => {
		toggleDropdownVisibility(!dropdownVisibility)
	}

	const generateDivOptions = () => {
		const divOptions = []

		for (const option of options) {
			divOptions.push(
				<div
					data-cy="selector_options"
					className="item"
					id={`${name}_${option.value}`}
					key={`${name}_${option.value}`}
					onClick={() => updateDropdownValue(option)}
				>
					{option.label}
				</div>
			)
		}

		return divOptions
	}

	const updateDropdownValue = (option: Options) => {
		setLabelOptionsSelected(option.label)
		setState(option.value)
	}

	return (
		<TagmeSelectStyles
			colorBackground={venueInfo.color.background || COLORS.background}
			colorAccentBackground={venueInfo.color.accentBackground || COLORS.accentBackground}
			colorMain={venueInfo.color.main || COLORS.main}
			colorBorder={venueInfo.color.border || COLORS.border}
		>
			<div
				ref={ref}
				data-cy={`${name}_selector_div`}
				className={`container${border ? '' : ' no_border'}${featuredEnabled && valid ? ' valid' : ''}${hasError ? ' has_error' : ''
					}`}
				onClick={toggleDropdown}
			>
				<div className="form-group">
					<div className="input-group">
						<div className="input-items">
							<div className="icon_container">
								<i className={`icon tagmeicon ${icon}`}></i>
								{featuredEnabled && valid ? <i className="valid tagmeicon tagmeicon-ok"></i> : ''}
							</div>

							<div className="text_container">
								<p className="label">{label}</p>

								<p className="label_value_selected">{labelOptionsSelected || placeholder}</p>

								<input type="hidden" name={name} value={state[name]} readOnly />
							</div>
						</div>

						<div className="icon_container clickable">
							<i
								className={`tagmeicon tagmeicon-seta select-arrow ${dropdownVisibility ? 'rotate-select-arrow' : ''
									}`}
							></i>
						</div>
					</div>
				</div>

				<div className={`dropdown${dropdownVisibility ? '' : ' invisible'}`}>{generateDivOptions()}</div>
			</div>
			{props.name !== "section" && featuredEnabled && valid ? (
				<p className="alert_message">
					{venuesListSpecial.includes(venueId as string)
						? `${t('Be advised! Valid only for the number of people selected.')}`
						: `${t('Be advised! The table is only available for.')} ${state} 
            ${state === 1 ? t('person.') : t('people.')}`}
				</p>
			) : (
				''
			)}
		</TagmeSelectStyles>
	)
}

export default TagmeSelect
