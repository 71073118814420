import { Route, BrowserRouter, Switch } from 'react-router-dom'

// Redux
import { Provider } from 'react-redux'
import store from './store/configureStore'

// Components
import WaitList from './Pages/WaitList'
import NotFoundPage from './Pages/NotFoundPage'
import RemoteWaitList from './Pages/RemoteWaitList'
import TermsOfService from './Pages/TermsOfService'

//Sentry
import * as Sentry from "@sentry/react";

function App() {
	return (
		<BrowserRouter>
			<Provider store={store}>
				<Switch>
					<Route exact path="/waitlist/:waitlistId" component={WaitList} />
					<Route exact path="/remote-waitlist/:venueId" component={RemoteWaitList} />
					<Route exact path="/terms-service" component={TermsOfService} />
					<Route path="*" component={NotFoundPage} />
				</Switch>
			</Provider>
		</BrowserRouter>
	)
}

//export default App
export default Sentry.withProfiler(App);
